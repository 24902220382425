import { template as template_edb723352e7c4289816dadd8413126a5 } from "@ember/template-compiler";
const FKLabel = template_edb723352e7c4289816dadd8413126a5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
