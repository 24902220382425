import { template as template_55093784c63446b783956a5db5f91f63 } from "@ember/template-compiler";
const WelcomeHeader = template_55093784c63446b783956a5db5f91f63(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
