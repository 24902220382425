import { template as template_a39454e5babd46bc8e75a88342fe11a0 } from "@ember/template-compiler";
const EmptyState = template_a39454e5babd46bc8e75a88342fe11a0(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
